@import 'layout';


// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($columns: $grid-columns, $gutters: $grid-gutter-widths, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    min-height: 1px; // Prevent columns from collapsing when empty
    @each $breakpoint, $breakpointWidth in $breakpoints {
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        padding-right: (map_get($gutters, $breakpoint) / 2);
        padding-left: (map_get($gutters, $breakpoint) / 2);
      }
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }
    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none; // Reset earlier grid tiers
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i,  $columns, $breakpoints);
        }
      }

      .order#{$infix}-first { order: -1; }

      .order#{$infix}-last { order: $columns + 1; }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} { order: $i; }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }

      .padding-left-extend#{$infix} {
        @each $breakpoint, $breakpointWidth in $breakpoints {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            $space: map-get($grid-horizontal-spaces, $breakpoint);
            padding-left: $space + (map_get($gutters, $breakpoint) / 2);
          }
        }
      }
      .padding-right-extend#{$infix} {
        @each $breakpoint, $breakpointWidth in $breakpoints {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            $space: map-get($grid-horizontal-spaces, $breakpoint);
            padding-right: $space + (map_get($gutters, $breakpoint) / 2);
          }
        }
      }

      .no-padding-left-extend#{$infix} {
        @each $breakpoint, $breakpointWidth in $breakpoints {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            padding-left: (map_get($gutters, $breakpoint) / 2);
          }
        }
      }
      .no-padding-right-extend#{$infix} {
        @each $breakpoint, $breakpointWidth in $breakpoints {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            padding-right: (map_get($gutters, $breakpoint) / 2);
          }
        }
      }
    }
  }
}


/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container() {
  width: 100%;
  @each $breakpoint, $breakpointWidth in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      padding-right: (map_get($grid-gutter-widths, $breakpoint) / 2);
      padding-left: (map_get($grid-gutter-widths, $breakpoint) / 2);
    }
  }
  margin-right: auto;
  margin-left: auto;
}


// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}


@mixin make-container-semi-fluid($breakpoints: $grid-breakpoints) {
  @each $breakpoint, $breakpointWidth in $breakpoints {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      $space: map-get($grid-horizontal-spaces, $breakpoint);
      max-width: calc(100% - #{$space * 2});
    }
  }
}

@mixin make-row() {
  display: flex;
  //flex-wrap: wrap;
  flex-wrap: nowrap;
  @each $breakpoint, $breakpointWidth in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      margin-right: (map_get($grid-gutter-widths, $breakpoint) / -2);
      margin-left: (map_get($grid-gutter-widths, $breakpoint) / -2);
    }
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }
}

@mixin make-col-ready() {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  min-height: 1px; // Prevent collapsing
  @each $breakpoint, $breakpointWidth in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      padding-right: (map_get($grid-gutter-widths, $breakpoint) / 2);
      padding-left: (map_get($grid-gutter-widths, $breakpoint) / 2);
    }
  }
}

@mixin make-col($size, $columns: $grid-columns, $breakpoints: $grid-breakpoints) {
  flex: 0 0 percentage($size / $columns);
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage($size / $columns);

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {

      &.extend-to-right#{$infix} {
        flex: 0 0 auto;

        @each $breakpoint, $breakpointWidth in $breakpoints {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            $space: map-get($grid-horizontal-spaces, $breakpoint);
            max-width: calc(#{percentage($size / $columns)} + #{$space});
          }
        }
      }
      &.extend-to-left#{$infix} {
        flex: 0 0 auto;

        @each $breakpoint, $breakpointWidth in $breakpoints {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            $space: map-get($grid-horizontal-spaces, $breakpoint);
            max-width: calc(#{percentage($size / $columns)} + #{$space});
            margin-left: $space * -1;
          }
        }
      }
      &.extend-to-both#{$infix} {
        @each $breakpoint, $breakpointWidth in $breakpoints {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            $space: map-get($grid-horizontal-spaces, $breakpoint);
            flex: 0 0 calc(#{percentage($size / $columns)} + #{$space * 2});
            max-width: calc(#{percentage($size / $columns)} + #{$space * 2});
            margin-left: $space * -1;
          }
        }
      }

      &.no-extend-to-right#{$infix},
      &.no-extend-to-left#{$infix},
      &.no-extend-to-both#{$infix}{
        flex: 0 0 percentage($size / $columns);

        @each $breakpoint, $breakpointWidth in $breakpoints {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            max-width: percentage($size / $columns);
            margin-left: 0;
          }
        }
      }

      &.no-padding-left#{$infix} {
        padding-left: 0;
      }
      &.no-padding-right#{$infix} {
        padding-right: 0;
      }
      &.padding-left#{$infix} {
        padding-left: (map_get($grid-gutter-widths, $breakpoint) / 2);
      }
      &.padding-right#{$infix} {
        padding-right: (map_get($grid-gutter-widths, $breakpoint) / 2);
      }
      &.padding-left-extend#{$infix} {
        @each $breakpoint, $breakpointWidth in $breakpoints {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            $space: map-get($grid-horizontal-spaces, $breakpoint);
            padding-left: $space;
          }
        }
      }
      &.padding-right-extend#{$infix} {
        @each $breakpoint, $breakpointWidth in $breakpoints {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            $space: map-get($grid-horizontal-spaces, $breakpoint);
            padding-right: $space;
          }
        }
      }
    }
  }
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}



// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.
.container {
  @include make-container();
  @include make-container-max-widths();
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.
.container-fluid {
  @include make-container();
}

.container-semi-fluid {
  @include make-container();
  @include make-container-semi-fluid();
}

// Row
//
// Rows contain and clear the floats of your columns.
.row {
  @include make-row();
}

// Remove the negative margin from default .row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

// Columns
//
// Common styles for small and large grid columns
@include make-grid-columns();

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}
.flex-wrap {
  flex-wrap: wrap;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .d#{$infix}-none         { display: none !important; }
    .d#{$infix}-inline       { display: inline !important; }
    .d#{$infix}-inline-block { display: inline-block !important; }
    .d#{$infix}-block        { display: block !important; }
    .d#{$infix}-table        { display: table !important; }
    .d#{$infix}-table-row    { display: table-row !important; }
    .d#{$infix}-table-cell   { display: table-cell !important; }
    .d#{$infix}-flex         { display: flex !important; }
    .d#{$infix}-inline-flex  { display: inline-flex !important; }
  }
}


//
// Utilities for toggling `display` in print
//

@media print {
  .d-print-none         { display: none !important; }
  .d-print-inline       { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block        { display: block !important; }
  .d-print-table        { display: table !important; }
  .d-print-table-row    { display: table-row !important; }
  .d-print-table-cell   { display: table-cell !important; }
  .d-print-flex         { display: flex !important; }
  .d-print-inline-flex  { display: inline-flex !important; }
}


@keyframes ShutterIn01 {
  from {
    height: 10vh;
  }
  to {
    height: 110vh;
  }
}

@keyframes ShutterIn02 {
  from {
    height: 110vh;
    transform: rotate(0);
  }
  to {
    height: 87.2vh;
    transform: rotate(-2deg);
  }
}

@keyframes ShutterIn03 {
  from {
    height: 87.2vh;
    transform: rotate(-2deg);
  }
  to {
    height: 110vh;
    transform: rotate(0);
  }
}

@keyframes ShutterIn04 {
  from {
    height: 110vh;
    transform: rotate(0);
  }
  to {
    height: 102.9vh;
    transform: rotate(1deg);
  }
}

@keyframes ShutterIn05 {
  from {
    height: 102.9vh;
    transform: rotate(1deg);
  }
  to {
    height: 110vh;
    transform: rotate(0);
  }
}

@keyframes ShutterOut {
  0% {
    opacity: 1;
    height: 110vh;
  }
  99% {
    opacity: 0;
    height: 110vh;
  }
  100% {
    opacity: 0;
    height: 10vh;
  }
}

.Shutter {
  position: fixed;
  left: -10vw;
  top: -10vh;
  width: calc(100% + 20vw);
  height: 10vh;
  background-color: white;
  z-index: 10;
  border-left: 10vw solid white;
  border-right: 10vw solid white;
  box-sizing: border-box;
  border-bottom: 2px solid black;

  &.in {
    animation-name: ShutterIn01, ShutterIn02, ShutterIn03, ShutterIn04, ShutterIn05;
    animation-duration: 0.48s, 0.16s, 0.2s, 0.08s, 0.12s;
    animation-timing-function: ease-in, ease-out, ease-in, ease-out, ease-in;
    animation-fill-mode: none, none, none, none, forwards;
    animation-delay: 0s, 0.48s, 0.64s, 0.84s, 0.92s;
  }

  &.out {
    height: 110vh;
    animation-name: ShutterOut;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
  }
}


@keyframes ShutterInnerIn {
  from {
    height: 0;
    visibility: visible;
  }
  to {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    visibility: visible;
  }
}

@keyframes ShutterInnerOut {
  0% {
    opacity: 1;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    visibility: visible;
    position: absolute;
  }
  99% {
    opacity: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    visibility: visible;
  }
  100% {
    opacity: 0;
    height: 0;
    visibility: hidden;
    position: fixed;
  }
}

.Shutter-inner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
  z-index: 11;
  height: 0;
  clip: rect(0, auto, auto, 0);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //visibility: hidden; // was it for some safari fixes?
  pointer-events: none;

  .HeaderClaim {
    opacity: 0;
  }

  &.in {
    height: 0;
    animation-name: ShutterInnerIn;
    animation-duration: 0.48s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-delay: 0s;

    .HeaderClaim {
      transition-property: opacity;
      transition-duration: 0s;
      transition-delay: 1.35s;
      opacity: 1;
    }
  }
  &.out {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    animation-name: ShutterInnerOut;
    animation-duration: 0s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-delay: 0.8s;
    position: absolute;

    .HeaderClaim {
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0s;
      transition-delay: 0.8s;
    }
  }
}

$buttonXlLeftOut: -57px;

.Button {
  position: relative;

  .btn {
    border: none;
    outline: none;
    background-color: black;
    color: white;
    text-align: center;
    cursor: pointer;
    @extend .font-hn-bold;
    font-size: 13px;
    letter-spacing: 0.14em;
    height: 40px;
    line-height: 42px;
    @include media-breakpoint-up('lg') {
      font-size: 15px;
      letter-spacing: 0.15em;
    }
    @include media-breakpoint-up('xl') {
      font-size: 36px;
      height: 85px;
      line-height: 89px;
    }
    text-transform: uppercase;
    padding: 0 15px;
    width: 100%;
    text-decoration: none;
    display: inline-block;

    .icon-container {
      height: 15px;
      vertical-align: -3px;
      display: inline-block;
      padding-right: 15px;
      @include media-breakpoint-up('xl') {
        height: 30px;
        padding-right: 30px;
        margin-left: 15px;
      }

      img.arrow {
        transition: transform 0.5s;
        display: inline-block;

        @include media-breakpoint-up('xl') {
          width: 30px;
        }
      }
    }

    &.btn-hover {
      position: absolute;
      top: 0;
      left: 0;
      color: black;
      background-color: transparent;

      .overlay-container {
        left: 100%;
        right: 0;
        position: absolute;
        overflow: hidden;
        top: 0;
        bottom: 0;
        transition: left 0.5s;

        .yellow {
          position: absolute;
          left: 29px;
          right: 0;
          height: 100%;
          background-color: $color-yellow;

          @include media-breakpoint-up('xl') {
            left: 62px;
          }

        }
        svg {
          position: absolute;
          height: 40px;
          left: 0;

          @include media-breakpoint-up('xl') {
            height: 85px;
          }
        }
      }

      .title {
        opacity: 0;
        position: relative;
        clip-path: polygon(100% 0, 100% 0, 100% 100%, calc(100% + 25px) 100%);
        @include media-breakpoint-up('xl') {
          clip-path: polygon(100% 0, 100% 0, 100% 100%, calc(100% + 52px) 100%);
        }
        transition: clip-path 0.5s;
      }
    }
  }

  &.block {
    .btn {
      display: block;

      &.btn-hover {
        display: none;
        @include media-breakpoint-up('sm') {
          display: block;
        }
      }
    }
  }

  &.white {
    .btn-default {
      background-color: white;
      color: black;
    }
  }
  &.outline.white {
    .btn-default {
      border: 2px solid black;
      height: 38px;
      line-height: 38px;
      padding-left: 13px;

      @include media-breakpoint-up('xl') {
        font-size: 36px;
        height: 83px;
        line-height: 87px;
      }
    }
    .btn-hover {
      .title {
        @include media-breakpoint-up('xl') {
          line-height: 90px;
        }
      }
    }
  }
  &.onBlackBg {
    .btn-hover {
      .overlay-container {
        svg {
          .st1 {
            fill: #ffffff;
            stroke: #ffffff;
          }
        }
      }
    }
  }
  &.textLeft {
    .btn {
      text-align: left;
    }
  }

  &:hover, &.hover {
    .btn-hover {
      .overlay-container {
        left: -25px;
        @include media-breakpoint-up('xl') {
          left: -52px;
        }
      }
      .title {
        opacity: 1;
        clip-path: polygon(-25px 0, 100% 0, 100% 100%, 0 100%);
        @include media-breakpoint-up('xl') {
          clip-path: polygon(-52px 0, 100% 0, 100% 100%, 0 100%);
        }
      }
    }
  }

  &.arrowDown {
    .icon-container {
      img.arrow {
        transform: rotate(90deg);
      }
    }
  }
}

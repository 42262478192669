$logoLeft: 62px;
$logoLeftOut: -120px;

$logoLeftXl: 142px;
$logoLeftOutXl: -280px;

.logo-text-hide {
  left: $logoLeftOut;
  clip-path: polygon(calc(25px + #{$logoLeft - $logoLeftOut}) 0, 101% 0, 100% 100%, #{$logoLeft - $logoLeftOut} 100%);
  @include media-breakpoint-up('xl') {
    left: $logoLeftOutXl;
    clip-path: polygon(calc(61px + #{$logoLeftXl - $logoLeftOutXl}) 0, 101% 0, 100% 100%, #{$logoLeftXl - $logoLeftOutXl} 100%);
  }
}

.logo-text-show {
  left: $logoLeft !important;
  clip-path: polygon(25px 0, 100% 0, 100% 100%, 0 100%) !important;
  @include media-breakpoint-up('xl') {
    left: $logoLeftXl !important;
    clip-path: polygon(61px 0, 100% 0, 100% 100%, 0 100%) !important;
  }
}

.LogoContainer {
  z-index: 3;
  cursor: pointer;
  position: absolute;
  left: map_get($grid-horizontal-spaces, 'xs');
  top: map_get($grid-horizontal-spaces, 'xs');
  @include media-breakpoint-up('sm') {
    position: fixed;
  }
  @include media-breakpoint-up('md') {
    position: absolute;
  }
  @include media-breakpoint-up('lg') {
    position: fixed;
  }
  @each $breakpoint, $breakpointWidth in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint) {
      left: map_get($grid-horizontal-spaces, $breakpoint);
      top: map_get($grid-horizontal-spaces, $breakpoint);
    }
  }
  @include media-breakpoint-up-until-and-max-height('xs', map_get($grid-breakpoints, 'lg'), 320px) {
    display: none;
  }
  @include media-breakpoint-up-and-max-height('lg', 500px) {
    display: none;
  }

  .logo {
    width: 55px;
    @include media-breakpoint-up('sm') {
      width: 74px;
    }
    @include media-breakpoint-up('lg') {
      width: 85px;
    }
    @include media-breakpoint-up('xl') {
      width: 200px;
    }
    vertical-align: bottom;
    position: relative;
    z-index: 3;
  }

  .logo-text {
    position: absolute;
    z-index: 1;
    bottom: 0;
    height: 44px;
    transition: left, clip-path 0.5s, 0.5s;
    left: $logoLeft;
    clip-path: polygon(25px 0, 101% 0, 100% 100%, 0 100%);

    @include media-breakpoint-up('xl') {
      height: 105px;
      left: $logoLeftXl;
      clip-path: polygon(61px 0, 101% 0, 100% 100%, 0 100%);
    }
  }

  &.hover {
    .logo-text {
      @extend .logo-text-show;
    }
  }
}

body.logoTextHidden {
  .LogoContainer {
    .logo-text {
      @extend .logo-text-hide;
    }
  }
}

.edge {
  .LogoContainer {
    .logo-text {
      left: $logoLeft !important;
      clip-path: unset !important;
    }
  }
}

.MainMenu {
  position: fixed;
  left: 0;
  padding-left: map-get($grid-horizontal-spaces, 'xs');
  bottom: 0;
  right: 0;
  padding-right: map-get($grid-horizontal-spaces, 'xs');
  z-index: 4;
  justify-content: space-between;
  background-color: black;
  text-align: center;
  clip: rect(0, auto, auto, 0);

  @include media-breakpoint-up('sm') {
    left: map-get($grid-horizontal-spaces, 'sm');
    padding-left: 0;
    bottom: #{map-get($grid-horizontal-spaces, 'sm') - 4px};
    right: unset;
    padding-right: 0;
    background-color: transparent;
    text-align: left;
    clip: unset;
  }

  @include media-breakpoint-up('md') {
    left: 0;
    padding-left: map-get($grid-horizontal-spaces, 'md');
    bottom: 0;
    right: 0;
    padding-right: map-get($grid-horizontal-spaces, 'md');
    background-color: black;
    text-align: center;
    clip: rect(0, auto, auto, 0);
  }

  @include media-breakpoint-up('lg') {
    left: map-get($grid-horizontal-spaces, 'lg');
    padding-left: 0;
    bottom: #{map-get($grid-horizontal-spaces, 'lg') - 5px};
    right: unset;
    padding-right: 0;
    background-color: transparent;
    text-align: left;
    clip: unset;
  }

  @include media-breakpoint-up('xl') {
    left: map-get($grid-horizontal-spaces, 'xl');
    bottom: #{map-get($grid-horizontal-spaces, 'xl') - 5px};
  }

  .MainMenuItem {
    position: relative;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 15px;

    &:nth-of-type(1) {
      position: absolute;
      left: map-get($grid-horizontal-spaces, 'xs');
    }

    &:nth-of-type(3) {
      position: absolute;
      right: map-get($grid-horizontal-spaces, 'xs');
    }

    @include media-breakpoint-up('sm') {
      margin-top: 30px;
      margin-bottom: 0;

      &:nth-of-type(1) {
        margin-top: 0;
      }

      &:nth-of-type(1), &:nth-of-type(3) {
        position: relative;
        left: unset;
        right: unset;
      }
    }

    @include media-breakpoint-up('md') {
      margin-top: 15px;
      margin-bottom: 15px;

      &:nth-of-type(1) {
        margin-top: 15px;
        position: absolute;
        left: map-get($grid-horizontal-spaces, 'md');
      }

      &:nth-of-type(3) {
        position: absolute;
        right: map-get($grid-horizontal-spaces, 'md');
      }
    }

    @include media-breakpoint-up('lg') {
      margin-top: 50px;
      margin-bottom: 0;

      &:nth-of-type(1) {
        margin-top: 0;
      }

      &:nth-of-type(1), &:nth-of-type(3) {
        position: relative;
        left: unset;
        right: unset;
      }
    }

    //clip-path: polygon(-100% -100%, calc(100% + 6px) -100%, calc(100% + 41px) 200%, -100% 200%);

    @include media-breakpoint-up('xl') {
      margin-top: 100px;
      clip-path: polygon(-100% -100%, calc(100% + 32px) -100%, calc(100% + 42px) 200%, -100% 200%);
    }

    a {
      display: block;
      text-decoration: none;
      text-transform: uppercase;
      color: white;
      @extend .font-hn-regular;
      font-size: 13px;
      letter-spacing: 0.1em;
      @include media-breakpoint-up('sm') {
        color: black;
      }
      @include media-breakpoint-up('md') {
        color: white;
      }
      @include media-breakpoint-up('lg') {
        font-size: 15px;
        letter-spacing: 0.15em;
        color: black;
      }
      @include media-breakpoint-up('xl') {
        font-size: 36px;
      }
      white-space: nowrap;
    }

    .mainMenu-item-overlay {
      position: absolute;
      left: -5px;
      right: -5px;
      top: -5px;
      height: 24px;
      @include media-breakpoint-up('lg') {
        left: -15px;
        right: -15px;
        top: -9px;
        height: 32px;
      }
      @include media-breakpoint-up('xl') {
        height: 62px;
        top: -12px;
        left: -30px;
        right: -30px;
      }
      visibility: hidden;
      /*background-color: $color-yellow;
      position: absolute;
      left: -12px;
      top: -5px;
      padding: 5px 10px 3px 18px;
      width: calc(100% + 18px + 10px);
      clip-path: polygon(100% 0, 100% 0, calc(100% + 3px) 100%, calc(100% + 18px) 100%);
      @include media-breakpoint-up('sm') {
        left: -18px;
      }
      @include media-breakpoint-up('lg') {
        left: -24px;
        top: -9px;
        padding: 9px 13px 6px 24px;
        width: calc(100% + 24px + 13px);
        clip-path: polygon(100% 0, 100% 0, calc(100% + 6px) 100%, calc(100% + 26px) 100%);
      }
      @include media-breakpoint-up('xl') {
        left: -58px;
        top: -9px;
        padding: 18px 35px 14px 58px;
        width: calc(100% + 58px + 35px);
        clip-path: polygon(100% 0, 100% 0, calc(100% + 6px) 100%, calc(100% + 60px) 100%);
      }
      transition: clip-path 0.4s;*/

      .overlay-container {
        left: 100%;
        right: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        transition: left 0.5s;
        clip: rect(-50px, auto, 120px, 0);

        .yellow {
          position: absolute;
          right: 0;
          height: 100%;
          background-color: $color-yellow;
          left: 26px;

          @include media-breakpoint-up('lg') {
            left: 32px;
          }
          @include media-breakpoint-up('xl') {
            left: 67px;
          }

        }
      }

      svg {
        position: absolute;
        left: 0;
        height: 43px;
        top: -9px;

        @include media-breakpoint-up('lg') {
          height: 57px;
          top: -12px;
        }

        @include media-breakpoint-up('xl') {
          height: 110px;
          top: -23px;
        }

        .st1 {
          stroke: white !important;

          @include media-breakpoint-up('sm') {
            stroke: black !important;
          }
          @include media-breakpoint-up('md') {
            stroke: white !important;
          }
          @include media-breakpoint-up('lg') {
            stroke: black !important;
          }
        }
      }
      .title {
        position: relative;
        margin-top: 6px;
        margin-left: 0;
        clip-path: polygon(100% 0, 100% 0, 100% 100%, calc(100% + 26px) 100%);

        @include media-breakpoint-up('sm') {
          margin-left: 5px;
        }

        @include media-breakpoint-up('lg') {
          margin-top: 9px;
          margin-left: 15px;
          clip-path: polygon(100% 0, 100% 0, 100% 100%, calc(100% + 32px) 100%);
        }

        @include media-breakpoint-up('xl') {
          margin-top: 12px;
          margin-left: 30px;
          clip-path: polygon(100% 0, 100% 0, 100% 100%, calc(100% + 67px) 100%);
        }

        opacity: 0;
        transition: clip-path 0.5s;
      }
    }

    &.active {
      a {
        color: white;

        @include media-breakpoint-up('sm') {
          color: black;
        }

        @include media-breakpoint-up('md') {
          color: white;
        }
        @include media-breakpoint-up('lg') {
          color: black;
        }
      }
      .mainMenu-item-overlay {
        visibility: visible;
        .overlay-container {
          left: -18px;
          @include media-breakpoint-up('lg') {
            left: -32px;
          }
          @include media-breakpoint-up('xl') {
            left: -42px;
          }
        }
        .title {
          opacity: 1;
          clip-path: polygon(-18px 0, 100% 0, 100% 100%, 0 100%);
          @include media-breakpoint-up('lg') {
            clip-path: polygon(-32px 0, 100% 0, 100% 100%, 0 100%);
          }
          @include media-breakpoint-up('xl') {
            clip-path: polygon(-367px 0, 100% 0, 100% 100%, 0 100%);
          }

          a {
            color: black;

            @include media-breakpoint-up('sm') {
              color: black;
            }

            @include media-breakpoint-up('md') {
              color: black;
            }
            @include media-breakpoint-up('lg') {
              color: black;
            }
          }
        }
      }
      /*.mainMenu-item-overlay {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 15px 100%);
        @include media-breakpoint-up('lg') {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 20px 100%);
        }
        @include media-breakpoint-up('xl') {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 48px 100%);
        }
        a {
          color: black;
        }
      }*/
      .line-left {
        left: -5px;
        @include media-breakpoint-up('sm') {
          left: -10px;
        }
        @include media-breakpoint-up('lg') {
          left: -14px;
        }
        @include media-breakpoint-up('xl') {
          left: -25px;
        }
      }
    }

    &.active, &.hover {
      a {
        @extend .font-hn-bold;
      }
    }

  }

  &.white {
    background-color: white;

    @include media-breakpoint-up('sm') {
      background-color: transparent;
    }

    @include media-breakpoint-up('md') {
      background-color: white;
    }

    @include media-breakpoint-up('lg') {
      background-color: transparent;
    }

    .MainMenuItem {
      a {
        color: black;
        @include media-breakpoint-up('sm') {
          color: white;
        }
        @include media-breakpoint-up('md') {
          color: black;
        }
        @include media-breakpoint-up('lg') {
          color: white;
        }
      }
      .mainMenu-item-overlay {

        svg {
          .st1 {
            stroke: black !important;

            @include media-breakpoint-up('sm') {
              stroke: white !important;
            }
            @include media-breakpoint-up('md') {
              stroke: black !important;
            }
            @include media-breakpoint-up('lg') {
              stroke: white !important;
            }
          }
        }
        a {
          color: black;
        }
      }
    }
  }
}

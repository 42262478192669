.Work {
  margin-bottom: 80px;
  @include media-breakpoint-up('sm') {
    margin-bottom: 110px;
  }
  @include media-breakpoint-up('xl') {
    margin-bottom: 220px;
  }

  h3.heading-with-line {
    @extend .font-hn-heavy;
    font-size: 23px;
    letter-spacing: 0.046em;
    border-bottom: 2px solid black;
    padding-bottom: 2px;

    @include media-breakpoint-up('lg') {
      font-size: 35px;
      letter-spacing: 0.02em;
    }
    @include media-breakpoint-up('xl') {
      font-size: 82px;
    }
  }

  .col-sp {
    @include media-breakpoint-up-until('sm', 800px) {
      max-width: 100%;
      flex: 0 0 100%;
    }
    @include media-breakpoint-up-until('md', 800px) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  .project {
    margin-top: 32px;
    text-decoration: none;
    color: black;
    display: block;

    @include media-breakpoint-up('sm') {
      margin-top: 40px;
    }
    @include media-breakpoint-up('xl') {
      margin-top: 120px;
    }

    h4 {
      @extend .font-hn-heavy;
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 22px;

      @include media-breakpoint-up('lg') {
        font-size: 17px;
        letter-spacing: 0.03em;
        line-height: 29px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 40px;
        line-height: 73px;
      }
    }

    p {
      @extend .font-hn-regular;
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 22px;

      @include media-breakpoint-up('lg') {
        font-size: 17px;
        letter-spacing: 0.03em;
        line-height: 29px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 40px;
        line-height: 73px;
      }
    }

    .type {
      padding-left: 0;
      position: relative;

      > div {
        position: absolute;
        bottom: 0.2em;
        @extend .font-hn-heavy;
        color: $color-grey-3;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.065em;
        line-height: 14px;

        @include media-breakpoint-up('lg') {
          font-size: 14px;
          letter-spacing: 0.065em;
          line-height: 17px;
        }
        @include media-breakpoint-up('xl') {
          font-size: 33px;
          line-height: 39px;
        }
      }
    }

    img {
      width: 100%;
      margin-top: 10px;
      vertical-align: bottom;
    }

    &:hover {
      img {
        filter: grayscale(100%);
      }
    }
  }
}

.workProject {
  @each $breakpoint, $breakpointWidth in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint) {
      margin-top: map_get($grid-horizontal-spaces, $breakpoint);
    }
  }
  margin-top: 100px;

  margin-bottom: 80px;
  @include media-breakpoint-up('sm') {
    margin-bottom: 110px;
  }

  @include media-breakpoint-up-until('sm', 800px) {
    margin-top: #{map_get($grid-horizontal-spaces, 'sm') + 40px};
  }

  @include media-breakpoint-up('lg') {
    margin-top: map_get($grid-horizontal-spaces, 'lg');
  }
  @include media-breakpoint-up('xl') {
    margin-top: map_get($grid-horizontal-spaces, 'xl');
    margin-bottom: 220px;
  }

  .workProject-header {
    @each $breakpoint, $breakpointWidth in $grid-breakpoints {
      @include media-breakpoint-up($breakpoint) {
        margin-bottom: map_get($grid-horizontal-spaces, $breakpoint);
      }
    }
    @include media-breakpoint-up-until('md', map_get($grid-breakpoints, 'lg')) {
      margin-bottom: 30px;
    }
    h5 {
      @extend .font-hn-heavy;
      font-size: 13px;
      letter-spacing: 0.05em;
      line-height: 16px;

      @include media-breakpoint-up('lg') {
        font-size: 17px;
        letter-spacing: 0.03em;
        line-height: 29px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 40px;
        line-height: 73px;
      }
    }
    p {
      @extend .font-hn-regular;
      font-size: 13px;
      letter-spacing: 0.05em;
      line-height: 16px;

      @include media-breakpoint-up('lg') {
        font-size: 17px;
        letter-spacing: 0.03em;
        line-height: 29px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 40px;
        line-height: 73px;
      }

      &.partners, .grey {
        color: $color-grey-4;
      }
    }

    .spacer {
      height: 1em;
    }

    @include media-breakpoint-up-until('sm', 800px) {
      .client-col,
      .project-col {
        max-width: 75%;
        flex: 0 0 75%;
      }
      .project-col {
        margin-left: 25%;
      }
      .client-project-spacer {
        display: block !important;
      }
    }
    @include media-breakpoint-up('md') {
      .project-col {
        margin-left: 0;
      }
    }
  }
  .workProject-content {
    img {
      width: 100%;
      vertical-align: bottom;
    }
    .spacer {
      height: 1em;
      @include media-breakpoint-up('lg') {
        height: map_get($grid-gutter-widths, 'lg');
      }
    }
    .text {
      @each $breakpoint, $breakpointWidth in $grid-breakpoints {
        @include media-breakpoint-up($breakpoint) {
          margin-top: map_get($grid-horizontal-spaces, $breakpoint);
          margin-bottom: map_get($grid-horizontal-spaces, $breakpoint);
        }
      }
      @include media-breakpoint-up-until('md', map_get($grid-breakpoints, 'lg')) {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      @extend .font-hn-heavy;
      font-size: 15px;
      letter-spacing: 0.02em;
      line-height: 23px;

      @include media-breakpoint-up-until('sm', 800px) {
        .text-col {
          margin-left: 0;
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      @include media-breakpoint-up('lg') {
        font-size: 27px;
        letter-spacing: 0.02em;
        line-height: 37px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 82px;
        line-height: 122px;
      }
    }
    @include media-breakpoint-up-until('sm', 800px) {
      .quote-col {
        margin-left: 25%;
        max-width: 75%;
        flex: 0 0 75%;
      }
    }
    .quote {
      margin-bottom: 38px;

      @include media-breakpoint-up('lg') {
        margin-bottom: 110px;
      }

      img {
        vertical-align: bottom;
      }
      .quote-left {
        margin-top: 38px;

        @include media-breakpoint-up-until('sm', 800px) {
          margin-left: 0;
          max-width: 25%;
          flex: 0 0 25%;
        }
        @include media-breakpoint-up('lg') {
          margin-top: 110px;
        }
      }
      .quote-right-smaller-container {
        position: relative;

        @include media-breakpoint-up-until('sm', 800px) {
          display: block !important;
        }

        .quote-right-smaller {
          position: absolute;
          width: 100%;
          transform: translateY(-50%);
        }

        .quote-author-img {
          position: absolute;
          bottom: 0;
          left: 0;
          border: 2px solid white;
          border-radius: 50%;
        }
      }
      .quote-text {
        margin-top: 1em;

        @include media-breakpoint-up-until('sm', map_get($grid-breakpoints, 'lg')) {
          margin-top: calc(1em + 20px);
        }
        @include media-breakpoint-up-until('sm', 800px) {
          margin-left: 0;
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 1em;
        }

        @include media-breakpoint-up('lg') {
          margin-top: 103px;
        }

        p {
          @extend .font-hn-heavy;
          font-size: 15px;
          letter-spacing: 0.02em;
          line-height: 23px;

          @include media-breakpoint-up('lg') {
            font-size: 27px;
            letter-spacing: 0.02em;
            line-height: 37px;
          }
          @include media-breakpoint-up('xl') {
            font-size: 82px;
            line-height: 122px;
          }
        }

        .quote-author-info {
          margin-top: 1em;
          @extend .font-hn-heavy;
          text-align: right;
          font-size: 13px;
          letter-spacing: 0.05em;
          line-height: 18px;
          @include media-breakpoint-up('xl') {
            font-size: 40px;
            line-height: 73px;
          }

          .quote-author-role {
            @extend .font-hn-regular;
            text-transform: uppercase;
          }
        }
      }
      .quote-right-bigger-col-left,
      .quote-right-bigger-col-right{
        @include media-breakpoint-up-until('sm', 800px) {
          display: none !important;
        }
        &.quote-right-bigger-col-left {
          @include media-breakpoint-up-until('sm', map_get($grid-breakpoints, 'lg')) {
            padding-right: 10px;
          }
        }
        &.quote-right-bigger-col-right {
          @include media-breakpoint-up-until('sm', map_get($grid-breakpoints, 'lg')) {
            padding-left: 10px;
          }
        }

      }
      .quote-right-left-bigger-container,
      .quote-right-right-bigger-container{
        position: relative;
        height: 100%;

        .quote-right-left-bigger,
        .quote-right-right-bigger {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;

          .quote-author-img {
            position: absolute;
            bottom: 0;
            left: 0;
            border: 2px solid white;
            border-radius: 50%;
          }
        }
      }
    }
    .credits {
      margin-top: 1em;
      @extend .font-hn-regular;
      font-size: 12px;
      letter-spacing: 0.02em;

      @include media-breakpoint-up('lg') {
        font-size: 17px;
        letter-spacing: 0.03em;
      }
      @include media-breakpoint-up('xl') {
        font-size: 40px;
      }

      strong {
        @extend .font-hn-heavy;
      }
    }
  }
}

.ProjectPreviewContainer {
  margin-top: 70px;

  @include media-breakpoint-up('md') {
    margin-top: 80px;
  }
  @include media-breakpoint-up('lg') {
    margin-top: 110px;
    margin-left: -30px;
  }
  @include media-breakpoint-up('xl') {
    margin-top: 220px;
    margin-left: -60px;
  }

  .container-title-row {
    h3 {
      @extend .font-hn-heavy;
      font-size: 23px;
      letter-spacing: 0.046em;
      padding-bottom: 5px;
      border-bottom: 2px solid black;

      @include media-breakpoint-up('lg') {
        font-size: 35px;
        letter-spacing: 0.02em;
        padding-bottom: 8px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 82px;
        padding-bottom: 20px;
      }
    }
  }
  .project-preview-col {
    margin-top: 32px;
    text-decoration: none;
    color: black;
    display: block;

    @include media-breakpoint-up('sm') {
      margin-top: 40px;
    }
    @include media-breakpoint-up('xl') {
      margin-top: 120px;
    }

    h4 {
      @extend .font-hn-heavy;
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 22px;

      @include media-breakpoint-up('lg') {
        font-size: 17px;
        letter-spacing: 0.03em;
        line-height: 29px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 40px;
        line-height: 73px;
      }
    }

    p {
      @extend .font-hn-regular;
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 22px;

      @include media-breakpoint-up('lg') {
        font-size: 17px;
        letter-spacing: 0.03em;
        line-height: 29px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 40px;
        line-height: 73px;
      }
    }

    img {
      width: 100%;
      margin-top: 10px;
      vertical-align: bottom;
    }

    &:hover {
      img {
        filter: grayscale(100%);
      }
    }
  }
}

@import '../fonts/myfonts_kit/Backslash_Seven_GmbH_Homepage.css';

.HelveticaNeueLTPro-Md, .font-hn-regular {
  font-family: HelveticaNeueLTPro-Md, sans-serif;
  font-weight: normal;
  font-style: normal;
}
@mixin font-hn-regular {
  font-family: HelveticaNeueLTPro-Md, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.HelveticaNeueLTPro-Hv, .font-hn-heavy {
  font-family: HelveticaNeueLTPro-Hv, sans-serif;
  font-weight: normal;
  font-style: normal;
}
@mixin font-hn-heavy {
  font-family: HelveticaNeueLTPro-Hv, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.HelveticaNeueLTPro-BlkEx, .font-hn-black-extended {
  font-family: HelveticaNeueLTPro-BlkEx, sans-serif;
  font-weight: normal;
  font-style: normal;
}
@mixin font-hn-black-extended {
  font-family: HelveticaNeueLTPro-BlkEx, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.HelveticaNeueLTPro-Bd, .font-hn-bold {
  font-family: HelveticaNeueLTPro-Bd, sans-serif;
  font-weight: normal;
  font-style: normal;
}
@mixin font-hn-bold {
  font-family: HelveticaNeueLTPro-Bd, sans-serif;
  font-weight: normal;
  font-style: normal;
}

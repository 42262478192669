.AgenturInfoTabs {
  position: relative;
  z-index: 4;
  overflow-x: hidden;

  .agentur-info-container {
    padding-bottom: 90px;
    @include media-breakpoint-up('lg') {
      padding-bottom: 200px;
    }
    @include media-breakpoint-up('xl') {
      padding-bottom: 360px;
    }
    color: white;

    .headline {
      padding-bottom: 11px;
      border-bottom: 2px solid $color-grey-2;
      position: relative;
      z-index: 2;

      .items {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-up('lg') {
          display: block;
          justify-content: inherit;
        }

        .item {
          background: none;
          border: none;
          color: $color-grey-1;
          font-size: 13px;
          letter-spacing: 0.08em;
          @include font-hn-bold;
          @include media-breakpoint-up('lg') {
            font-size: 15px;
            letter-spacing: 0.15em;
          }
          @include media-breakpoint-up('xl') {
            font-size: 35px;
          }
          cursor: pointer;
          text-transform: uppercase;
          outline: none;
          text-align: left;

          &.selected, &:hover {
            color: white;
            @extend .font-hn-heavy;
            @include media-breakpoint-up('lg') {
              letter-spacing: 0.128em;
            }
          }

          @include media-breakpoint-up('lg') {
            & + .item {
              margin-left: 55px;
            }
          }
          @include media-breakpoint-up('xl') {
            & + .item {
              margin-left: 150px;
            }
          }
        }
      }

      .active-line {
        position: absolute;
        height: 2px;
        bottom: -2px;
        background-color: white;
        transition: left, width 0.6s, 0.6s;
      }
    }

    .row-content {
      margin-top: 40px;
      @include media-breakpoint-up('lg') {
        margin-top: 50px;
      }
      @include media-breakpoint-up('xl') {
        margin-top: 110px;
      }
      position: relative;

      .agentur-content-container {
        overflow: hidden;
        width: calc(100% + 160px);
        margin-left: -80px;
        padding: 0 80px;
        position: relative;

        &:before {
          position: absolute;
          content: '';
          width: 50px;
          height: 100%;
          background-image: linear-gradient(to right, black, transparent);
          left: 0;
          top: 0;
          z-index: 4;
        }

        &:after {
          position: absolute;
          content: '';
          width: 50px;
          height: 100%;
          background-image: linear-gradient(to right, transparent, black);
          right: 0;
          top: 0;
          z-index: 4;
        }

        .agentur-inner-container {
          width: calc(300% + 160px);
          position: relative;
          z-index: 3;

          &.withAnimation {
            transition: left 1s;
          }

          .agentur-content {
            position: relative;
            display: inline-block;
            width: calc(33.333333333333% - 53.33333333333px);
            vertical-align: top;

            &.no-width {
              width: 0;
              height: 0;
              margin: 0;
              overflow: hidden;
            }

            &:not(.no-width) + .agentur-content {
              margin-left: 80px;
            }

            h3 {
              @extend .font-hn-heavy;
              font-size: 23px;
              letter-spacing: 0.046em;
              line-height: 36px;

              @include media-breakpoint-up('lg') {
                font-size: 35px;
                letter-spacing: 0.03em;
                line-height: 55px;
              }
              @include media-breakpoint-up('xl') {
                font-size: 82px;
                letter-spacing: 0.02em;
                line-height: 122px;
              }
            }

            h3 + .content {
              margin-top: 20px;
              @include media-breakpoint-up('xl') {
                margin-top: 70px;
              }
            }

            .content {
              font-size: 14px;
              letter-spacing: 0.05em;
              line-height: 22px;

              @include media-breakpoint-up('lg') {
                font-size: 17px;
                letter-spacing: 0.03em;
                line-height: 29px;
              }
              @include media-breakpoint-up('xl') {
                font-size: 40px;
                line-height: 73px;
              }

              ul {
                list-style-type: none;
                margin-right: 50px;
                @include media-breakpoint-up('xl') {
                  margin-right: 120px;
                }

                li {
                  &:before {
                    content: "\\";
                    display: inline-block;
                    padding-right: 0.8em;
                    @extend .font-hn-black-extended;
                  }
                }
              }
            }

            .content + .Button {
              margin-top: 10px;
              @include media-breakpoint-up('lg') {
                margin-top: 50px;
              }
              @include media-breakpoint-up('xl') {
                margin-top: 70px;
              }
            }

            .Button {
              @include media-breakpoint-up('sm') {
                width: 56%;
              }
              @include media-breakpoint-up('md') {
                width: 100%;
              }
              @include media-breakpoint-up('lg') {
                width: 43%;
              }
            }

            img.referenceLogos {
              width: 100%;
              margin-top: 50px;

              @include media-breakpoint-up('lg') {
                margin-top: 100px;
              }
              @include media-breakpoint-up('xl') {
                margin-top: 220px;
              }

              &.smaller {
                @include media-breakpoint-up('sm') {
                  width: 50%;
                }
                @include media-breakpoint-up('md') {
                  margin-left: 25%;
                }
                @include media-breakpoint-up('lg') {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

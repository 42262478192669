@import 'layout';
@import 'fonts';
@import 'colors';
@import 'buttons';
@import 'FullSizeText';
@import 'bgBlack';
@import 'SideMenu';
@import 'MainMenu';
@import 'LogoContainer';
@import 'headerClaim';
@import 'AgenturInfoTabs';
@import 'persons';
@import 'Jobs';
@import 'Shutter';
@import 'Kontakt';
@import 'FooterMenu';
@import 'work';
@import 'BackToWorkButton';
@import 'privacy';
@import 'imprint';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 100%;
  @extend .font-hn-regular;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.zindex-unset {
  z-index: unset;
}

.mt-1 {
  margin-top: 1em;
}

.text-right {
  text-align: right;
}

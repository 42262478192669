.Jobs {
  margin-top: 90px;
  margin-bottom: 80px;
  @include media-breakpoint-up('sm') {
    margin-bottom: 50px;
  }
  @include media-breakpoint-up('md') {
    margin-bottom: 110px;
  }
  @include media-breakpoint-up('lg') {
    margin-top: 150px;
  }
  @include media-breakpoint-up('xl') {
    margin-top: 300px;
    margin-bottom: 220px;
  }
  position: relative;
  z-index: 1;

  .claim {
    @extend .font-hn-black-extended;
    text-transform: uppercase;
    letter-spacing: -0.0013em;
    line-height: 0.863em;
    font-size: 1em;
  }

  .intro {
    margin-top: 30px;
    font-size: 15px;
    line-height: 23px;

    @include media-breakpoint-up('lg') {
      margin-top: 60px;
      font-size: 35px;
      line-height: 55px;
    }
    @include media-breakpoint-up('xl') {
      margin-top: 120px;
      font-size: 82px;
      line-height: 122px;
    }

    @extend .font-hn-heavy;
    letter-spacing: 0.02em;
  }

  .offers {
    margin-top: 40px;
    @include media-breakpoint-up('xl') {
      margin-top: 70px;
    }

    .JobOffer {
      margin-bottom: 5px;
      @include media-breakpoint-up('xl') {
        margin-bottom: 25px;
      }

      .jobOffer-content-container {
        @extend .font-hn-regular;
        font-size: 14px;
        letter-spacing: 0.05em;
        line-height: 22px;
        padding: 15px 13px 20px 13px;

        @include media-breakpoint-up('lg') {
          font-size: 17px;
          letter-spacing: 0.03em;
          line-height: 29px;
          padding: 14px 32px;
        }
        @include media-breakpoint-up('xl') {
          font-size: 40px;
          line-height: 73px;
          padding: 70px 70px;
        }

        b {
          @extend .font-hn-bold;
        }

        ul {
          margin-right: 50px;
          list-style-type: none;

          li {
            display: flex;
            &:before {
              content: "\\";
              display: inline-block;
              padding-right: 0.8em;
              @extend .font-hn-black-extended;
            }
          }
        }

        p + ul {
          margin-top: 0.3em;
        }
        ul + p {
          margin-top: 0.6em;
        }

      }
    }
  }
}

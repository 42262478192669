.Privacy {
  margin-top: 155px;
  margin-bottom: 90px;

  @include media-breakpoint-up('sm') {
    margin-top: map_get($grid-horizontal-spaces, 'sm');
    margin-bottom: map_get($grid-horizontal-spaces, 'sm');
  }
  @include media-breakpoint-up('md') {
    margin-top: 235px;
    margin-bottom: 90px;
  }
  @include media-breakpoint-up('lg') {
    margin-top: map_get($grid-horizontal-spaces, 'lg');
    margin-bottom: map_get($grid-horizontal-spaces, 'lg');
  }
  @include media-breakpoint-up('xl') {
    margin-top: map_get($grid-horizontal-spaces, 'xl');
    margin-bottom: map_get($grid-horizontal-spaces, 'xl');
  }

  h2 {
    @extend .font-hn-heavy;
    font-size: 23px;
    letter-spacing: 0.046em;
    line-height: 36px;

    @include media-breakpoint-up('lg') {
      font-size: 35px;
      letter-spacing: 0.02em;
      line-height: 55px;
    }
    @include media-breakpoint-up('xl') {
      font-size: 82px;
      line-height: 122px;
    }
  }
  .part {
    margin-top: 90px;
    @include media-breakpoint-up('xl') {
      margin-top: 220px;
    }

    h4 {
      @extend .font-hn-bold;
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 22px;

      @include media-breakpoint-up('lg') {
        font-size: 17px;
        letter-spacing: 0.03em;
        line-height: 29px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 40px;
        line-height: 73px;
      }
    }
    h4 + .text {
      margin-top: 1.5em;
    }
    .text {
      @extend .font-hn-regular;
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 22px;

      @include media-breakpoint-up('lg') {
        font-size: 17px;
        letter-spacing: 0.03em;
        line-height: 29px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 40px;
        line-height: 73px;
      }

      p + p {
        margin-top: 1.5em;
      }
    }
    .text + .conclusion {
      margin-top: 1.5em;
    }
    .conclusion {
      @extend .font-hn-bold;
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 22px;

      @include media-breakpoint-up('lg') {
        font-size: 17px;
        letter-spacing: 0.03em;
        line-height: 29px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 40px;
        line-height: 73px;
      }
    }
  }
  .footer {
    margin-top: 1.5em;
    @extend .font-hn-regular;
    font-size: 11px;
    letter-spacing: 0.03em;

    @include media-breakpoint-up('lg') {
      font-size: 12px;
      letter-spacing: 0.03em;
    }
    @include media-breakpoint-up('xl') {
      font-size: 33px;
    }
  }
}

.FooterMenu {
  padding-bottom: 80px;

  @include media-breakpoint-up-until-and-max-height('sm', map_get($grid-breakpoints, 'lg'), 540px) {
    display: block !important
  }
  @include media-breakpoint-up-until-and-max-height('sm', 800px, 440px) {
    display: block !important;
  }
  @include media-breakpoint-up-until-and-max-height('lg', map_get($grid-breakpoints, 'xl'), 670px) {
    display: block !important;
  }
  @include media-breakpoint-up-and-max-height('xl', 1130px) {
    display: block !important;
  }

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: $color-grey-1;
    @extend .font-hn-bold;
    letter-spacing: 0.155em;
    font-size: 11px;

    &.active {
      color: black;
    }
  }

  &.black {
    background-color: black;

    a {
      &.active {
        color: white;
      }
    }
  }
}

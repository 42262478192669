.BackToWorkButton {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  @include media-breakpoint-up-until('xs', map_get($grid-breakpoints, 'sm')) {
    top: -2px !important;
  }

  @include media-breakpoint-up('sm') {
    left: map-get($grid-horizontal-spaces, 'sm');
    transform: none;
  }

  @include media-breakpoint-up-until('sm', 800px) {
    left: 50%;
    top: -2px !important;
    transform: translateX(-50%);
  }

  @include media-breakpoint-up('md') {
    left: 50%;
    transform: translateX(-50%);
  }
  @include media-breakpoint-up-until('md', map_get($grid-breakpoints, 'lg')) {
    top: -2px !important;
  }

  @include media-breakpoint-up('lg') {
    left: map-get($grid-horizontal-spaces, 'lg');
    transform: none;
  }

  @include media-breakpoint-up('xl') {
    left: map-get($grid-horizontal-spaces, 'xl');
  }

  .btn-default {
    border: 2px solid black;
    line-height: 38px;
    padding: 0 40px;

    @include media-breakpoint-up('xl') {
      line-height: 85px;
    }
  }
}

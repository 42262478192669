.Imprint {
  margin-top: 155px;
  margin-bottom: 90px;

  @extend .font-hn-regular;
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 22px;

  @include media-breakpoint-up('sm') {
    margin-top: map_get($grid-horizontal-spaces, 'sm');
    margin-bottom: map_get($grid-horizontal-spaces, 'sm');
  }
  @include media-breakpoint-up('md') {
    margin-top: 235px;
    margin-bottom: 90px;
  }
  @include media-breakpoint-up('lg') {
    margin-top: map_get($grid-horizontal-spaces, 'lg');
    margin-bottom: map_get($grid-horizontal-spaces, 'lg');
    font-size: 17px;
    letter-spacing: 0.03em;
    line-height: 29px;
  }
  @include media-breakpoint-up('xl') {
    margin-top: map_get($grid-horizontal-spaces, 'xl');
    margin-bottom: map_get($grid-horizontal-spaces, 'xl');
    font-size: 40px;
    line-height: 73px;
  }

  b {
    @extend .font-hn-bold;
  }

  .address-col {
    @extend .font-hn-heavy;
    font-size: 23px;
    letter-spacing: 0.046em;
    line-height: 36px;
    margin-top: 90px;

    @include media-breakpoint-up('lg') {
      font-size: 35px;
      letter-spacing: 0.02em;
      line-height: 55px;
      margin-top: 110px;
    }
    @include media-breakpoint-up('xl') {
      font-size: 82px;
      line-height: 122px;
      margin-top: 220px;
    }
  }
  .info1-col-lg {
    margin-top: 117px;

    @include media-breakpoint-up('xl') {
      margin-top: 230px;
    }
  }

  .mail-col,
  .phone-col,
  .info2-col,
  .info1-col {
    margin-top: 30px;

    @include media-breakpoint-up('lg') {
      margin-top: 110px;
    }
    @include media-breakpoint-up('xl') {
      margin-top: 220px;
    }
  }

  .button-info {
    text-align: center;
    @extend .font-hn-bold;
    font-size: 13px;
    letter-spacing: 0.1em;
    margin-top: 20px;
    @include media-breakpoint-up('lg') {
      font-size: 15px;
      letter-spacing: 0.15em;
    }
    @include media-breakpoint-up('xl') {
      font-size: 36px;
      margin-top: 60px;
    }
  }


  .part {
    margin-top: 90px;

    @include media-breakpoint-up('lg') {
      margin-top: 110px;
    }
    @include media-breakpoint-up('xl') {
      margin-top: 220px;
    }

    h4 {
      @extend .font-hn-heavy;
      font-size: 23px;
      letter-spacing: 0.046em;
      line-height: 36px;

      @include media-breakpoint-up('lg') {
        font-size: 35px;
        letter-spacing: 0.02em;
        line-height: 55px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 82px;
        line-height: 122px;
      }
    }
    h4 + .text {
      margin-top: 1.5em;
    }
    .text {
      p + p {
        margin-top: 1.5em;
      }
    }
  }
}

.Kontakt {
  border-bottom: 25px solid black;
  @include media-breakpoint-up('sm') {
    border-bottom: 0;
  }

  .col-kontakt-title {
    h3 {
      @extend .font-hn-heavy;
      font-size: 23px;
      letter-spacing: 0.046em;
      padding-bottom: 5px;
      @include media-breakpoint-up('lg') {
        font-size: 35px;
        letter-spacing: 0.02em;
        padding-bottom: 10px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 82px;
        padding-bottom: 10px;
      }
      border-bottom: 2px solid black;
    }
  }
  .contact-row {
    margin-top: 40px;

    @include media-breakpoint-up('xl') {
      margin-top: 120px;
    }

    .button-info {
      text-align: center;
      @extend .font-hn-bold;
      font-size: 13px;
      letter-spacing: 0.1em;
      margin-top: 20px;
      @include media-breakpoint-up('lg') {
        font-size: 15px;
        letter-spacing: 0.15em;
      }
      @include media-breakpoint-up('xl') {
        font-size: 36px;
        margin-top: 60px;
      }
    }

    .col-mail {
      @include media-breakpoint-up-until('sm', 800px) {
        max-width: 75%;
        flex: 0 0 75%;
      }
    }
    .col-mail-phone-spacer {
      @include media-breakpoint-up-until('sm', 800px) {
        display: block !important;
        margin-top: 1.5em;
      }
    }
    .col-phone {
      @include media-breakpoint-up-until('sm', 800px) {
        max-width: 75%;
        flex: 0 0 75%;
        margin-left: 25%;
      }
      @include media-breakpoint-up('md') {
        margin-left: 0;
      }
    }
  }
  .location-row {
    margin-top: 50px;
    margin-bottom: 60px;

    @include media-breakpoint-up('md') {
      margin-top: 100px;
      margin-bottom: 100px;
    }

    @include media-breakpoint-up('lg') {
      margin-top: 110px;
      margin-bottom: 110px;
    }
    @include media-breakpoint-up('xl') {
      margin-top: 220px;
      margin-bottom: 220px;
    }

    h4 {
      @extend .font-hn-heavy;
      font-size: 20px;
      letter-spacing: 0.046em;
      line-height: 30px;
      @include media-breakpoint-up('lg') {
        font-size: 35px;
        letter-spacing: 0.02em;
        line-height: 55px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 82px;
        line-height: 122px;
      }
      margin-top: -0.3em;
    }
  }
  .image-row {
    img {
      width: 100%;
      vertical-align: bottom;
    }
  }

  .bg-black-container {
    color: white;
    .col-kontakt-title {
      h3 {
        border-bottom: 2px solid white;
      }
    }
  }
}

.bg-black-container {
  background-color: #000000;
  position: relative;
  z-index: 4;
  opacity: 0;
  visibility: hidden;

  &.in {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s;
  }
  &.out {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0s 0.5s;
  }

  &.absolute {
    position: absolute;
    width: 100%;
  }

  .bg-black-helper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    clip: rect(0, auto, auto, 0);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    z-index: 5;
    pointer-events: none;

    .LogoContainer {
      display: none;
      pointer-events: all;
    }
    .MainMenu {
      pointer-events: all;
    }

    @include media-breakpoint-up('sm') {
      right: 66vw;

      .LogoContainer {
        display: block;
      }
    }
    @include media-breakpoint-up('md') {
      right: 0;

      .LogoContainer {
        display: none;
      }
    }
    @include media-breakpoint-up('lg') {
      right: 66vw;

      .LogoContainer {
        display: block;
      }
    }
  }
}

.safari {
  .bg-black-container {
    .bg-black-helper {
      clip: unset;
    }
  }
}

.SideMenu {
  position: fixed;
  right: 30px;
  transform: rotate(-90deg);
  transform-origin: right top;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  transition: width 0.1s;

  @each $breakpoint, $breakpointWidth in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint) {
      width: calc(100vh - #{map_get($grid-horizontal-spaces, $breakpoint) * 2});
      width: calc(var(--vh, 1vh) * 100 - #{map_get($grid-horizontal-spaces, $breakpoint) * 2});
      top: map_get($grid-horizontal-spaces, $breakpoint);
    }
  }

  @include media-breakpoint-up('sm') {
    justify-content: space-between;
    right: 40px;
  }
  @include media-breakpoint-up-until('sm', 800px) {
    right: 30px;
  }
  @include media-breakpoint-up-until-and-max-height('sm', map_get($grid-breakpoints, 'lg'), 540px) {
    justify-content: flex-end;
  }
  @include media-breakpoint-up-until-and-max-height('sm', 800px, 440px) {
    justify-content: flex-end;
  }
  @include media-breakpoint-up-until-and-max-height('lg', map_get($grid-breakpoints, 'xl'), 670px) {
    justify-content: flex-end;
  }
  @include media-breakpoint-up-and-max-height('xl', 1130px) {
    justify-content: flex-end;
  }

  @include media-breakpoint-up('md') {
    width: calc(100vh - #{map_get($grid-horizontal-spaces, 'md') * 2 + 46px});
    width: calc(var(--vh, 1vh) * 100 - #{map_get($grid-horizontal-spaces, 'md') * 2 + 46px});
  }
  @include media-breakpoint-up('lg') {
    width: calc(100vh - #{map_get($grid-horizontal-spaces, 'lg') * 2});
    width: calc(var(--vh, 1vh) * 100 - #{map_get($grid-horizontal-spaces, 'lg') * 2});
  }
  @include media-breakpoint-up('xl') {
    width: calc(100vh - #{map_get($grid-horizontal-spaces, 'xl') * 2});
    width: calc(var(--vh, 1vh) * 100 - #{map_get($grid-horizontal-spaces, 'xl') * 2});
    right: 90px;
  }

  a, button {
    text-transform: uppercase;
    text-decoration: none;
    color: $color-grey-1;
    @extend .font-hn-bold;

    font-size: 11px;
    letter-spacing: 0.155em;
    @include media-breakpoint-up('sm') {
      font-size: 15px;
      letter-spacing: 0.150em;
    }
    @include media-breakpoint-up-until('sm', 800px) {
      font-size: 11px;
      letter-spacing: 0.155em;
    }
    @include media-breakpoint-up('xl') {
      font-size: 25px;
      letter-spacing: 0.15em;
    }
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;

    img {
      margin-left: 8px;
      vertical-align: -2px;
      @include media-breakpoint-up('sm') {
        margin-left: 14px;
        vertical-align: -1px;
      }

      &.black {
        display: none;
      }
    }

    &:hover,
    &.active {
      color: black;

      img {
        display: none;

        &.black {
          display: inline;
        }
      }
    }
  }

  a.optional {
    @include media-breakpoint-up-until-and-max-height('sm', map_get($grid-breakpoints, 'lg'), 540px) {
      display: none !important
    }
    @include media-breakpoint-up-until-and-max-height('sm', 800px, 440px) {
      display: none !important;
    }
    @include media-breakpoint-up-until-and-max-height('lg', map_get($grid-breakpoints, 'xl'), 670px) {
      display: none !important;
    }
    @include media-breakpoint-up-and-max-height('xl', 1130px) {
      display: none !important;
    }
  }
}

.personsClaim-container {
  position: relative;
  z-index: 3;

  .FullSizeText {

    .FullSizeText-text {
      vertical-align: bottom;

      .personsClaim {
        white-space: nowrap;
        text-transform: uppercase;
        height: 1.74em;
        overflow: visible;
        @extend .font-hn-black-extended;
        color: $color-grey-2;
        letter-spacing: -0.019em;
        position: relative;
        z-index: 3;
        line-height: 1em;

        @include media-breakpoint-up('sm') {
          height: 0.91em;
          line-height: 1.27em;
        }
        @include media-breakpoint-up('md') {
          height: 1.78em;
          line-height: 1em;
        }
        @include media-breakpoint-up('lg') {
          height: 0.91em;
          line-height: 1.25em;
        }
        @include media-breakpoint-up('xl') {
          height: 0.91em;
          line-height: 1.25em;
        }
      }
    }
  }
}

.Persons {
  position: relative;
  z-index: 2;
  transition: margin-top 0.4s;

  .persons-black-bg-helper {
    background-color: black;
    height: calc((100vw - #{map-get($grid-horizontal-spaces, 'xs') * 2}) / 4);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  .person {
    margin-bottom: 20px;
    @include media-breakpoint-up('lg') {
      margin-bottom: 50px;
    }
    @include media-breakpoint-up('xl') {
      margin-bottom: 70px;
    }

    img {
      width: 100%;
    }
    h4 {
      @extend .font-hn-heavy;
      margin-top: 2px;
      font-size: 12px;
      line-height: 19px;
      @include media-breakpoint-up('lg') {
        margin-top: 10px;
        font-size: 17px;
        line-height: 29px;
      }
      @include media-breakpoint-up('xl') {
        margin-top: 25px;
        font-size: 40px;
        line-height: 73px;
      }
      letter-spacing: 0.03em;
    }
    p {
      @extend .font-hn-regular;
      font-size: 12px;
      line-height: 19px;

      @include media-breakpoint-up('lg') {
        font-size: 17px;
        line-height: 29px;
      }
      @include media-breakpoint-up('xl') {
        font-size: 40px;
        line-height: 73px;
      }
      letter-spacing: 0.03em;
    }
  }
}

@keyframes headerClaimIn01 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}

@keyframes headerClaimIn02 {
  0% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(2deg);
  }
}

@keyframes headerClaimIn03 {
  0% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes headerClaimOut {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-90deg);
  }
}

.headerClaim-container {
  margin-top: 100px;
  margin-bottom: 65px;
  height: calc(100vh - 165px);
  height: calc(var(--vh, 1vh) * 100 - 165px);
  transition: height 0.1s;

  @include media-breakpoint-up('sm') {
    margin-top: 50px;
    margin-bottom: 50px;
    height: calc(100vh - 100px);
    height: calc(var(--vh, 1vh) * 100 - 100px);
  }

  @include media-breakpoint-up('md') {
    margin-top: 100px;
    margin-bottom: 146px;
    height: calc(100vh - 246px);
    height: calc(var(--vh, 1vh) * 100 - 246px);
  }

  @include media-breakpoint-up('lg') {
    margin-top: 110px;
    margin-bottom: 110px;
    height: calc(100vh - 220px);
    height: calc(var(--vh, 1vh) * 100 - 220px);
  }

  @include media-breakpoint-up('xl') {
    margin-top: 220px;
    margin-bottom: 220px;
    height: calc(100vh - 440px);
    height: calc(var(--vh, 1vh) * 100 - 440px);
  }

  .FullSizeText {
    position: relative;

    .FullSizeText-text {
      position: absolute;
      bottom: 0;

      > h2 {
        @extend .font-hn-black-extended;
        text-transform: uppercase;
        letter-spacing: -0.0016em;
        line-height: 0.87em;
        font-size: 1em;
        bottom: -0.14em;
        position: relative;

        @include media-breakpoint-up('xl') {
          bottom: -0.16em;
        }

        .smallerLetterSpacing {
          letter-spacing: -0.04em;
        }

        small {
          font-size: 0.75em;
          line-height: 0;
        }

        .changingWord-container {
          height: 0.87em;
          position: relative;
          overflow: hidden;

          .changingWord {
            position: absolute;
            top: -105%;
            transition: top 0.3s ease-out;

            &.in {
              top: 0;
            }
            &.out {
              top: 105%;
            }
          }
        }

        &.higherLines {
          line-height: 1.05em;
          bottom: -0.22em;
          position: relative;

          .changingWord-container {
            height: 1.05em;
          }
        }
      }
    }
  }
}
.overlay-header {
  position: absolute;
  width: 100%;

  .headerClaim-container {
    .FullSizeText {
      .FullSizeText-text {
        > h2 {
          color: white;
        }
      }
    }
  }
}

.HeaderClaims {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  transition: height 0.1s;

  .HeaderClaim {
    position: relative;
    transform-origin: 156% calc(100% - 100px);
    transform: rotate(90deg);
    transition: top 0.1s;

    &:nth-of-type(2) {
      top: -100vh;
      top: calc(var(--vh, 1vh) * -100);
    }
    &:nth-of-type(3) {
      top: -200vh;
      top: calc(var(--vh, 1vh) * -200);
    }
    &:nth-of-type(4) {
      top: -300vh;
      top: calc(var(--vh, 1vh) * -300);
    }
    &:nth-of-type(5) {
      top: -400vh;
      top: calc(var(--vh, 1vh) * -400);
    }
    &:nth-of-type(6) {
      top: -500vh;
      top: calc(var(--vh, 1vh) * -500);
    }

    &.initial {
      transform: rotate(0deg);
    }
    &.in {
      animation-name: headerClaimIn01, headerClaimIn02, headerClaimIn03;
      animation-duration: 0.6s, 0.12s, 0.08s;
      animation-timing-function: ease-out, ease, ease-in;
      animation-fill-mode: none, none, forwards;
      animation-delay: 0s, 0.6s, 0.72s;
    }
    &.out {
      animation-name: headerClaimOut;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in;
    }
  }
}
